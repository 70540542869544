.editValue {
    position: absolute;
    width: calc(100% + 0.7rem);
    height: 100%;
    top: 0;
    left: -0.7rem;
    z-index: 2;
    background-color:#EAEAEA ;
}

.editValue > input {
    width: 80%;
    height: 100%;
    background-color:#EAEAEA ;
    border: none;
    outline: none;
}

.editValue > button {
    width: 10%;
    height: 100%;
    background-color:#EAEAEA ;
    border: none;
    outline: none;
}
