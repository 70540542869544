.main {
    color: blue;
}

.main:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.disabled {
	pointer-events: none;
	opacity: 0.3;
	cursor: auto;
}