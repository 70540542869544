.main {
    margin: 0.5rem 0.4rem;
    display: flex;
    flex-direction: column;
}

.main>h3 {
    font-size: 0.75rem;
}

.main>select {
    width: 100%;
    height: 1.7rem;
    border: none;
}

.btn {
    display: flex;
    flex-direction: row-reverse;
}