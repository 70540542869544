.main {
    position: relative;
}
.form-field {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.form-field:focus {
   outline: none;
}  