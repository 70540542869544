.main {
    margin: 4% 2%;
    overflow-y: auto;
    height: 80vh;
}

.mainWithHelpGuide {
    margin: 2%;
    overflow-y: auto;
    height: 80vh;
}

.helpGuideContainer {
    width: 100%;
    text-align: center;
}

.helpGuide {
    width: 70%;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 3px;
}

.btns {
    width: 100%;
    text-align: center;
}

.learningMode {
    position: absolute;
    top: 3.5rem;
    right: 1rem;
    color: red;
}

.btnWrapper {
    display: inline-block;
    vertical-align: middle;
    margin: 1rem;
    cursor: pointer;
}