.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background: rgba(0, 0, 0, 0.75);
}

.modal {
    padding: 1rem;
    position: fixed;
    left: 50%;
    top: 50%;
    width: 18rem;
    height: 17rem;
    z-index: 1000;
    transform: translate(-50%,-50%);
    background-color: white;
    border-radius: 1%;
}

.container {
    padding: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center
}

.container h2 {
    margin-top: 1.5rem;
}

.action {
    position: absolute;
    bottom: 1rem;
}

.messagebox {
    margin-top: 1.5rem;
}