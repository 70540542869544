.textBtn {
    min-width: 4rem;
    color: #0E5FBC;
}

.textBtn:disabled {
    color: gray;
}

.textBtn:hover:not(:disabled) {
    background: #E6E2E2;
    color: #0E5FBC;
}

.textBtn:focus {
    outline: 0;
    box-shadow: none;
}