.main {
    border: 1px solid #ccc;
    min-width: 40rem;
    max-width: 40rem;
    border-radius: 2px;
    height: 97%;
    margin: 0 0.8rem;
}

.header {
    padding-top: 1rem;
    height: 10%;
    display: flex;
    flex-direction: row;
    padding-left: 1rem;
}

.separator {
    display: flex;
    width: 100%;
    height: 10%;
    align-items: center;
    padding-left: 1rem;
    padding-top: 1rem;
}

.separator>label {
    padding-top: 0.35rem;
}

.separator input {
    border: 1px solid #CCC;
    border-radius: 2px;
    margin-left: 0.5rem;
}

.container {
    width: 100%;
    margin-top: 2rem;
    height: calc(80% - 2rem);
    overflow-y: auto;
}

.containerBody {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    height: 95%;
}

.leftContainer {
    height: 100%;
}

.parameterContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-width: 14rem;
    width: 14rem;
    border: 1px solid #CCC;
    position: relative;
}

.parameterContainerHeader {
    display: flex;
    position: sticky;
    padding: 0.25rem;
    top: 0;
    background-color: white;
    z-index: 1;
    border-bottom: 1px solid #ccc;
}

.parameterContainerHeader>h3 {
    font-size: 0.75rem;
    margin: 0.5rem;
}

.parameterContainerBtns {
    position: absolute;
    right: 0.25rem;
    top: 0.25rem;
}

.parameterContainer span {
    width: 100%;
    height: 2rem;
    padding: 0.4rem 0.3rem 0.3rem 0.7rem;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.parameterContainerBody {
    width: 100%;
    overflow-y: auto;
}

.parameterContainerBody>div {
    width: 100%;
    height: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.parameterContainerBody>div>span {
    width: 50%;
    display: block;
}

.parameterContainerBody>div>input {
    width: 45%;
    height: 80%;
    display: block;
    margin: 0;
    padding: 0;
    padding-left: 0.2rem;
    border: 1px solid #CCC;
    border-radius: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rightContainer {
    height: 100%;
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.topContainer {
    height: 46%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 2rem;
}

.buttonContainer button {
    background-color: gainsboro;
    border: 1px solid #CCC;
    border-radius: 2px;
}

.bottomContainer {
    height: 46%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.exampleFilenameSpan {
    margin-right: 0.3rem;
    margin-left: 0.8rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.help {
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
}