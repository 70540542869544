.main {
    width: 17.5rem;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.main>label {
    margin-top: 0.5rem;
}

.container {
    width: 12.5rem;
}

.actions {
   display: flex;
   justify-content: right;
   margin-top: 1rem;
}

.actions button {
    margin-left: 1rem;
}
