.addValue {
    margin-bottom: 1rem;
    height: 2rem;
}

.addValue input {
    width: 10rem;
}

.buttons {
    display: flex;
    justify-content: right;
}
