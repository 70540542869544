.main {
    width: 30vw;
    height: 50vh;
    position: relative;
}

.body {
    padding: 0 3%;
    width: 90%;
    height: calc(100% - 2.5rem);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
}

.actions {
    position: absolute;
    right: 0;
    bottom: 0;
}

.actions button {
    margin-left: 1rem;
}