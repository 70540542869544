.main {
    width: 100%;
}

.optionDiv {
    height: 1.5rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.optionDiv>span {
    margin-left: 0.7rem;
}

.table {
    width: 100%;
}

.table>tbody>tr>td,
.table>thead>tr>th {
    padding: 0.2rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    margin: 0;
    text-align: center;
    vertical-align: middle;
}

.table>tbody>tr>td {
    border-top: 1px solid #ccc;
}

.nameField {
    white-space: nowrap;
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
}