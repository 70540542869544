.main {
    height: 7rem;
    min-width: 15rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.1rem solid gainsboro;
}

.leftContainer {
    width: 3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.checkBox {
    border: 1rem solid #ccc;
    margin-left: 0.8rem;
    margin-top: 0.4rem;
}

.rightContainer {
    padding-top: 1rem;
    height: 6rem;
    width: calc(100% - 4rem);
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
}

.item {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.text {
    width: 50%;
    padding-top: 0.4rem;
}

.itemRight {
    width: 35%;
    display: flex;
    flex-direction: row;
    justify-content: right;
}

.numberInputBox {
    height: 1.5rem;
    margin-top: 0.20rem;
    width: 3.5rem;
}

.timeInputBox {
    height: 1.5rem;
    margin-top: 0.20rem;
    width: 6rem;
}

.numberInputBox input,
.timeInputBox input {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #ccc;
}

.durationTypeBox {
    padding-top: 0.4rem;
    padding-left: 0.9rem;
    width: 30%;
    display: flex;
}