.main {
    display: block;
    padding: 0.375rem 0.75rem;
    width: 100%;
    background-color: #A52A2A;
    color: white;
    border-radius: 0.25rem;
    font-size: 0.75rem;
    font-weight: 400;
}

.link {
    background-color: #0E5FBC;
}

.link:hover {
    background-color: #007BFF;
    color: white;
}

.link:focus {
    outline: 0;
    box-shadow: none;
}