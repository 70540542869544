@import "../../Constants.css";

.main {
    width: 90vw;
    height: 80vh;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.leftContainer {
    width: 8%;
    position: relative;
    z-index: 2;
    text-align: left;
    height: 100%;
    overflow-y: auto;
}

.middleContainer {
    position: relative;
    width: 63%;
    z-index: 2;
    text-align: left;
    height: 100%;
}

.middleContainer h3 {
    margin: 0;
}

.middleContainerHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.rightContainer {
    position: relative;
    width: 25%;
    text-align: left;
    z-index: 2;
    height: 100%;
}

.jigFields {
    width: 100%;
    height: calc(100% - 2rem);
}

.jigField {
    display: flex;
    justify-content: left;
    width: 100%;
    margin-bottom: 0.5rem;
}

.jigField input {
    width: 100%;
    padding-left: 0.1rem;
}

.slotTable {
    width: 100%;
    height: calc(100% - 6rem);
    padding-right: 0.75rem;
    overflow-y: auto;
}

.slotTable input,
.slotTable select {
    width: 100%;
    min-width: 3.3rem;
    height: 1.5rem;
    padding-left: 0.1rem;
}

.slotTable>table>thead tr th {
    position: sticky;
    top: 0;
    background-color: white;
    border-bottom: 1px solid #ccc;
    z-index: 1;
}

.slotTable>table {
    border-collapse: separate;
    border-spacing: 0;
}

.table>tbody>tr>td,
.table>thead>tr>th {
    padding: 0.2rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin: 0;
}

.placement {
    display: flex;
    justify-content: center;
}

.actions {
    width: 100%;
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: right;
}

.actions>button {
    margin-left: 1rem;
}

.saveAsBtn {
    position: absolute;
    left: 0;
}

.addSlotBtn {
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: right;
}

.deleteAllSlotsBtn {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: left;
}

.previewContents {
    width: 100%;
    height: calc(100% - 5rem);
}

.previewHeading {
    display: flex;
    justify-content: left;
}

.previewMessage {
    display: flex;
    justify-content: left;
    color: var(--error-message-color);
}

.required {
    color: red;
    white-space: pre;
    font-size: 0.8rem;
    font-weight: bold;
    line-height: 1rem;
}