@import "../../Constants.css";

.main {
    width: 79vw;
    height: 84vh;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.leftContainer {
    width: 15%;
    height: 100%;
    position: relative;
    z-index: 2;
    text-align: left;
    overflow-y: auto;
}

.middleContainer {
    width: 40%;
    position: relative;
    z-index: 2;
    height: 100%;
    text-align: left;
}

.rightContainer {
    width: 40%;
    position: relative;
    text-align: left;
    z-index: 2;
    height: 100%;
}

.tableFields {
    width: 100%;
    height: calc(100% - 2rem);
}

.tableField {
    display: flex;
    justify-content: left;
    width: 100%;
    margin-bottom: 0.5rem;
}

.tableFieldHeading {
    display: flex;
    justify-content: left;
    width: 100%;
    margin-bottom: 0.5rem;
    font-weight: 600;
}

.tableField input {
    width: 100%;
    padding-left: 0.1rem;
}

.jigTable {
    width: 100%;
    height: calc(100% - 5rem);
    padding-right: 0.75rem;
    overflow-y: auto;
}

.jigTable input,
.jigTable select {
    width: 100%;
    min-width: 3.3rem;
    height: 1.5rem;
    padding-left: 0.1rem;
}

.jigTable>table>thead tr th {
    position: sticky;
    top: 0;
    background-color: white;
    border-bottom: 1px solid #dee2e6;
    z-index: 1;
}

.jigTable>table {
    border-collapse: separate;
    border-spacing: 0;
}

.addJigBtn {
    position: absolute;
    right: 0;
    bottom: 0;
}

.actions {
    width: 100%;
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: right;
}

.actions>button {
    margin-left: 1rem;
}

.saveAsBtn {
    position: absolute;
    left: 0;
}

.previewContent {
    width: 100%;
    height: calc(100% - 7rem);
}

.previewHeading {
    display: flex;
    justify-content: left;
}

.previewMessage {
    display: flex;
    flex-direction: column;
    justify-content: left;
    color: var(--error-message-color);
}

.previewMessage p {
    margin: 0;
    padding: 0;
    margin-top: 0.3rem;
}

.masterDataMessage {
    display: flex;
    flex-direction: column;
    width: 100%
}

.masterDataMessage p {
    margin: 0;
    margin-top: 0.3rem;
    padding: 0;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.required {
    color: red;
    white-space: pre;
    font-size: 0.8rem;
    font-weight: bold;
    line-height: 1rem;
}