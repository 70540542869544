.filterNode {
    height: 2rem;
    display: flex;
    align-items: center;
    position: relative;
}

.filterNode span {
    width: 40%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.editValue {
    position: absolute;
    width: calc(100% + 0.7rem);
    height: 100%;
    top: 0;
    left: -0.7rem;
    z-index: 2;
    background-color:#EAEAEA ;
}

.editValue > input {
    width: 80%;
    height: 100%;
    background-color:#EAEAEA ;
    border: none;
    outline: none;
}

.editValue > button {
    width: 10%;
    height: 100%;
    background-color:#EAEAEA ;
    border: none;
    outline: none;
}

.editButtons {
    display: flex;
    position: absolute;
    right: 2rem;
    top: 0;
}

.actionButton {
   display: flex;
   position: absolute;
   right: 6rem;
   top: 0;
}

.subMenuPopupButton {
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
    width: 2rem;
    height: 2rem;
}
