.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: rgba(0, 0, 0, 0.75);
    border-radius: inherit;
}

.modal {
    padding: 1.2rem;
    position: fixed;
    left: 50%;
    top: 50%;
    width: max-content;
    height: max-content;
    z-index: 10;
    transform: translate(-50%,-50%);
    background-color: white;
    border-radius: 1%;
}

.header {
    position: relative;
    margin-bottom: 1.2rem;
}

.closeBtn {
    line-height: 1rem;
    position: absolute;
    right: 0;
    top: 0;
}