.main {
    height: 45%;
    width: 32%;
    padding: 1rem;
    border: 1px solid #ccc;
}

.lists {
    height: 90%;
    width: 100%;
    overflow: auto;
}

.list {
    height: 2rem;
    width: 90%;
    display: flex;
    align-items: center;
}

.label {
    width: 100%;
    height: 1rem;
    padding-left: 0.5rem;
}

.label label {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
