.main {
    width: 100%;
    height: 100%;
}

.listContainer {
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.valueList {
    float: left;
    height: 60%;
    margin: 1rem;
}

.valueList h3 {
    font-size: 0.75rem;
    font-weight: 500;
}