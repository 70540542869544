.main {
    padding-left: 0.7rem;
    display: flex;
    flex-direction: row;
    color: black;
    width: 100%;
    padding-right: 1.2rem;
    height: 2rem;
    align-items: center;
}

.iconImage {
    width: 1rem;
    height: 1rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 0.4rem;
}

.main p {
    padding-top: 0.85rem;
}

.main:hover {
    background-color: #E0E0E0;
}