/* .main {
    width: 100vw;
    height: calc(100vh - 3rem);
    display: flex;
    flex-direction: column;
} */
.htmlViewerContainer{
   width: 100%;
   height: 100%; 
}

.htmlViewer{
    width: 60vw;
    height: 35vw;
    background-color: white;
}

.htmlViewer iframe{
    width: 100%;
    height: 100%;
}
.main{
    display: flex;
    /* flex-direction: row; */
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    margin-left: 1rem;
    gap:2rem;
}
.environmentHeading{
    margin-top: 1rem;
    margin-left: 1rem;
}
.body {
    /* height: 100%; */
    width: 100%;
    padding-top: 1%;
    padding-left: 1%;
    overflow: auto;
}

.logoContainer{
    width: 70%;
    margin-bottom: 2rem;
}
.logoContainer img{
    width: 100%;
}
.environmentDiv {
    padding-top: 1rem;
    padding-bottom: 1.5rem;
    width: 25%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.environmentDiv input {
    /* margin-left: 1rem; */
    /* border: 1px solid #ccc; */
    /* border-radius: 2px; */
    height: 100%;
    border: none;
    border-left: 1px solid #ccc ;
}

.instructions {
    margin-top: 2rem;
    display: flex;
}

.required {
    color: red;
    white-space: pre;
    font-size: 0.8rem;
    font-weight: bold;
    line-height: 1rem;
}

.inputBox{
    display: flex;
    gap: 0.25rem;
    border: 1px solid;
    width: max-content;
    border: 1px solid #ccc;
    height: 2rem;
    /* justify-content: center; */
    align-items: center;
    border-radius: 2px;
    /* padding-left: 0.25rem; */

}

.createEnvironmentHeading{
    font-size: 1rem;
}