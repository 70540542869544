.main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 10rem;
    height: 1.5rem;
}

.main label {
    padding-top: 0.2rem;
    width: 25%;
}

.searchBar {
    position: relative;
    width: 75%;
    height: 100%;
    margin-left: 10px;
}

.searchBar > input {
    position: absolute;
    left: 0;
    text-indent: 10%;
    border: 1px solid #ccc;
    width: 100%;
    height: 100%;
}

.searchBar > img {
    position: absolute;
    top: 20%;
    left: 4%;
    z-index: 1;
}