.main {
    padding: 0.8rem 1.25rem 2rem 1.25rem;
    height: calc(100vh - 3rem);
    width: 100vw;
    overflow: auto;
}

.backButton {
    width: 100%;
    height: 1rem;
}

.container {
    margin-top: 1rem;
    position: relative;
    min-width: 30rem;
    width: 45%;
}

.heading {
    display: flex;
    justify-content: left;
}

.btn {
    width: 100%;
    margin-top: 7%;
    display: flex;
    justify-content: right;
}