.main {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: white;
    width: 60vw;
    height: 60vh;
    padding: 0.75rem;
}

.main input:disabled {
    color:rgb(170, 170, 170);
}

.containerTop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.box {
    margin-right: 1rem;
}

.containerMiddle {
    width: 100%;
    height: 28vh;
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.containerLeft {
    width: 45%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.containerRight {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bigBox {
    width: 49%;
}

.selectBox {
    margin-top: 1rem;
    width: 100%;
}

.selectBox select, .selectBox input {
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    height: 2rem;
}

.assignToBox {
    margin-top: 3rem;
    width: 30%;
}

.action {
    position: absolute;
    right: 0;
    bottom: 0;
}

.action button {
    margin-left: 1rem;
}

.errorBox {
    margin-top: 2rem;
}