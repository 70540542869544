.filterTree {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90%;
    overflow-x: auto;
}

.filterLevel {
  display: flex;
  flex-direction: row;
  overflow: auto;
  height: 100%;
}

.filterField {
    display: flex;
    flex-direction: row;
}

.filterField >select {
    width: 12.5rem;
    height: 2rem;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.addBtn {
  margin-left: 1rem;
}

.showSelection {
    margin-top: 1rem;
}