.main {
    display: flex;
    justify-content: center;
}

.grid>input {
    width: 0.5rem;
    height: 0.5rem;
}

.grid {
    display: grid;
    grid-gap: 0.1rem;
    grid-template-columns: repeat(3, 0fr);
}

.point {
    width: 0.5rem;
    height: 0.5rem;
    position: relative;
}

.point>input[type=checkbox] {
    display: none;
    cursor: pointer;
}

.point>input[type=checkbox] + label::after {
    content: '';
    display: block;
    width: 0.5rem;
    height: 0.5rem;
    border: 1px solid rgb(104, 102, 102);
}

.point>input[type=checkbox]:checked + label::after {
    content: '';
    display: block;
    width: 0.5rem;
    height: 0.5rem;
    border: 1px solid rgb(104, 102, 102);
    background: rgb(75, 75, 75);
}

.point>input[type=checkbox]:disabled + label::after {
    opacity: 0.5;
}
