.main {
    padding-top: 0.8rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    width: 100vw;
    height: calc(100vh - 3rem);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.leftContainer {
    width: 49%;
    height: 100%;
}

.backButton {
    width: 100%;
    height: 1rem;
}

.container {
    margin-top: 1rem;
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 2rem;
}

.addUserBtn {
    position: absolute;
    right: 0;
    top: 0;
}

.usersTable {
    margin-top: 1rem;
    width: 100%;
    height: calc(100% - 5rem);
    overflow-y: auto;
    background-color: white;
}

.usersTable>table>thead tr th {
    position: sticky;
    top: 0;
    background-color: white;
    border-bottom: 1px solid #ccc;
    z-index: 1;
}

.usersTable>table {
    border-collapse: separate;
    border-spacing: 0;
}

.rightContainer {
    width: 49%;
    height: 100%;
}

.rightContainerHeader {
    margin-top: 2rem;
    width: 100%;
    height: 1rem;
}

.rightContainerHeader h2 {
    padding-left: 0.5rem;
    font-weight: 500;
}

.rightContainerContent {
    margin-top: 2rem;
    padding-top: 1rem;
    width: 100%;
    height: calc(100% - 6rem);
    background-color: white;
    position: relative;
}

.rightContainerStatusContent {
    margin-top: 5rem;
    padding-top: 1rem;
    padding-left: 0.25rem;
    width: 100%;
    height: calc(100% - 6rem);
    position: relative;
}

.outputPreset {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 0.5rem;
    height: 7.3rem;
    overflow-y: auto;
}

.selectTable {
    padding-left: 1.5rem;
    padding-bottom: 0.5rem;
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
}

.selectTable select,
.selectTable input {
    height: 2rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
}

.selectTable>div>select:focus {
    outline: none;
}

.updateBtn {
    height: inherit;
    display: flex;
    align-items: flex-end;
    white-space: nowrap;
}

.box {
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
    width: 32%;
}

.previewMessage {
    display: flex;
    flex-direction: column;
    justify-content: left;
}

.previewMessage p {
    margin: 0;
    margin: 0.2rem;
    padding: 0;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.preview {
    padding: 0.5rem 1.5rem;
    width: 100%;
    height: calc(100% - 14rem);
}