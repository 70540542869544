.main {
    display: flex;
    flex-direction: column;
    width: max-content;
    position: relative;
    height: max-content;
    align-items: center;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dropdownImage {
    width: 2rem;
    height: 2rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.header:active {
    color: gray;
}

.children {
    width: max-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 0;
    z-index: 100;
    right: 2.1rem;
    background-color: white;
    border: 1px solid #ccc;
}