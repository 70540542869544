.main {
    width: 31rem;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.mainForNewOrEdit {
    width: 65vw;
    height: 70vh;
    position: relative;
}

.body {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: row;
}

.bodyForNewOrEdit {
    padding: 1% 2%;
    height: calc(100% - 4rem);
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    overflow-y: auto;
}

.leftContainer {
    min-width: 15rem;
    display: flex;
    flex-direction: column;
}

.middleContainer {
    margin-left: 1rem;
    width: 20rem;
    min-width: 15rem;
}

.rightContainer {
    margin-left: 3rem;
    min-width: 12rem;
    width: 15rem;
    margin-right: 1rem;
}

.leftContainer input[type="email"],
.leftContainer select {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 12.5rem;
    min-height: 2rem;
}

.main label,
.mainForNewOrEdit label {
    margin-top: 1rem;
}

.middleContainer label {
    margin-top: 0;
}

.middleContainer {
    padding-top: 1rem;
}

.container {
    width: 12.5rem;
}

.actions {
    display: flex;
    justify-content: right;
    margin-top: 1rem;
}

.actions button {
    margin-left: 1rem;
}

.actionsForNewOrEdit {
    position: absolute;
    right: 0;
    bottom: 0;
}

.actionsForNewOrEdit button {
    margin-left: 1rem;
}