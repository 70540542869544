.addValue {
    display: flex;
    flex-direction: column;
}

.addValue > div > input {
    width: 10rem;
    height: 2rem;
    margin-left: 0.75rem;
    margin-top: 0.5rem;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.buttons {
    margin: 0.5rem;
    display: flex;
    justify-content: right;
}

.addValueButton {
    margin-right: 0.5rem;
}
