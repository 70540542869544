.main {
    padding-top: 0.8rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    width: 100vw;
    height: calc(100vh - 3rem);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.leftContainer {
    width: 49%;
    height: 100%;
}

.backButton {
    width: 100%;
    height: 1rem;
}

.container {
    margin-top: 1rem;
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 2rem;
}

.rightConatiner {
    width: 49%;
    height: 100%;
}

.primaryBtn {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
}

.primaryBtn button {
    margin-left: 1rem;
}

.heading {
    display: flex;
    justify-content: left;
}

.jigsTable {
    margin-top: 1rem;
    width: 100%;
    height: calc(100% - 5rem);
    overflow-y: auto;
    background-color: white;
}

.jigsTable>table>thead tr th {
    position: sticky;
    top: 0;
    background-color: white;
    border-bottom: 1px solid #dee2e6;
    z-index: 1;
}

.jigsTable>table {
    border-collapse: separate;
    border-spacing: 0;
}

.rightHeader {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: flex-end;
    width: 100%;
    height: 2rem;
}

.preview {
    margin-top: 1rem;
    width: 100%;
    height: calc(100% - 5rem);
    padding: 5%;
    background-color: white;
}

.preview h2 {
    white-space: nowrap;
    width: 15rem;
    height: 2rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

.formController {
    display: grid;
    align-items: center;
}

.formController>label {
    padding: 0;
}

.formController>div>input {
    height: 1.5rem;
    width: 12rem;
    margin: 0;
    outline: none;
    border: 1px solid grey;
    border-radius: 0.2rem;
}

.formController>div>label {
    margin-bottom: 0.25rem;
}

.formController>input {
    height: 1.5rem;
    width: 12rem;
    margin: 0;
    outline: none;
    border: 1px solid grey;
    border-radius: 0.2rem;
}

.formController>select {
    width: 100%;
    width: 12rem;
    outline: none;
    height: 1.5rem;
    border: 1px solid grey;
    border-radius: 0.2rem;
    font-size: 0.875rem;
}

.jigSizingArea {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.jigPreviewer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    height: 100%;
}

.jigPreviewer>div {
    width: 100%;
}

.footerButtonWrapper {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.createPrintModal {
    width: 50vw;
    height: 75vh;
    display: flex;
    justify-content: space-between;
}

.createPrintModal>div:first-child {
    width: 40%;
    height: 90%;
}

.createPrintModal>div:last-child {
    width: 60%;
    height: 90%;
    position: relative;
}

.field {
    display: flex;
    flex-direction: column;
}

.field>label {
    font-size: 0.75rem;
}

.titleLabel {
    margin-top: 1.5rem;
    font-weight: 600;
    font-size: 0.75rem;
}

.layoutName {
    margin: 0.25rem 0;
}

.location{
    padding: 0 1.875rem;
}