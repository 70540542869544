.autocomplete {
	position: relative;
	display: inline-block;
    width: max-content;
}

.autocompleteOuter {
    overflow-y: auto;
    position: absolute;
	z-index: 99;
	left: 0.75rem;
	width: 10rem;
    max-height: 8.5rem;
    background-color: white;
    border: 1px solid #CCC;
    border-top: none;
}

.autocompleteItems {
	left: 0;
    width: 100%;
}

.autocompleteItems div {
	padding: 5px;
	cursor: pointer;
    font-size: 0.75rem;
}

.autocompleteItems div:hover {
	background-color: rgb(234, 234, 234) !important;
}

.autocompleteItems div:last-child {
	border-bottom: none; 
}

.autocomplete input {
    width: 10rem;
    height: 2rem;
    margin-left: 0.75rem;
    margin-top: 0.5rem;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #ccc;
    border-radius: 4px;
}
