.main {
    padding-top: 0.8rem;
    padding-left: 1.25rem;
    height: 20vh;
    width: 45vw;
}

.backButton {
    width: 100%;
    height: 1rem;
}

.container {
    margin-top: 1rem;
    position: relative;
    width: 100%;
}

.heading {
    display: flex;
    justify-content: left;
}

.body {
    padding-left: 0.25rem;
}