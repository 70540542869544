.main {
    width: 30vw;
    height: 45vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    padding: 15px;
}

.linkWithList {
    height: 90%;
    width: 100%;
    overflow: auto;
}

.listItem {
    height: 2rem;
    width: 90%;
    display: flex;
    align-items: center;
}

.label {
    width: 100%;
    height: 1rem;
    padding-left: 0.5rem;
}

.label label {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.actions {
    position: absolute;
    display: flex;
    right: 0;
    bottom: 0;
    justify-content: right;
}

.actions button {
    margin-left: 1rem;
}
