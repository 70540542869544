.tabs {
}

.tabList {
    margin-top: 1rem;
    font-size: 1rem;
    font-weight: normal;
    padding-inline-start: 0;
}

.tabItemLink, .tabItemLink:hover {
    color: inherit;
}

.tabItem {
    display: inline;
    user-select: none;
    cursor: pointer;
    padding: 0 0.3rem 0.5rem 0.3rem;
    margin-left: 1rem;
    margin-right: 1rem;
    font-size: 0.9rem;
}

.tabItemActive {    
    border-bottom: 2px solid white;
}

.tabContent {
}
