.filterNode {
    height: 2rem;
    display: flex;
    align-items: center;
    position: relative;
}

.filterNode > span {
    width: 63%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.editValue {
    position: absolute;
    width: calc(100% + 0.7rem);
    height: 100%;
    top: 0;
    left: -0.7rem;
    z-index: 2;
    background-color:#EAEAEA ;
}

.editValue > input {
    width: 80%;
    height: 100%;
    padding-left: 1rem;
    background-color:#EAEAEA ;
    border: none;
    outline: none;
}

.editValue > button {
    width: 10%;
    height: 100%;
    background-color:#EAEAEA ;
    border: none;
    outline: none;
}

.filterNodeBtns {
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
}