.languageSelect {
    font-size: 1.2rem;
    color: white;
    border: 0;
    outline: 0;
    background-color: transparent;
}

.languageIcon {
    margin-right: 0.5rem;
}

.languageSelect>option {
    background-color: #333;
}