.main {
    padding-left: 5%;
    width: 20vw;
    height: 50vh;
    position: relative;
}

.header {
    margin-bottom: 1rem;
}

.container {
    width: 90%;
    height: 60%;
    overflow: auto;
}

.item {
    display: flex;
    height: 1.5rem;
    align-items: center;
}

.item input {
    margin-right: 0.5rem;
}

.item label {
    padding-top: 0.4rem;
}

.actions {
    position: absolute;
    bottom: 0;
    right: 0;
}

.actions button {
    margin-left: 1rem;
}