.main {
    width: 66vw;
    height: 75vh;
    position: relative;
}

.modalContainer {
    padding: 0 1%;
    height: 90%;
    overflow-y: auto;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
}

.leftContainer {
    height: 97%;
    margin: 0 0.8rem;
}

.container {
    border: 1px solid #ccc;
    min-width: 12.5rem;
    max-width: 12.5rem;
    border-radius: 2px;
    height: calc(100% - 9rem);
}

.customAddBtn {
    width: 100%;
    height: 7.5rem;
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
}

.customAddBtn>button {
    height: 2rem;
}

.sliderBtn {
    width: 100%;
    margin-top: 2rem;
    height: 3.5rem;
    display: flex;
    flex-direction: column;
}

.sliderBtn>p {
    height: 1rem;
    margin: 0;
    margin-bottom: 0.5rem;
}

.containerHeader {
    display: flex;
    padding: 0.25rem;
    border-bottom: 1px solid #ccc;
    width: 100%;
    height: 2.5rem;
}

.containerHeader>h3 {
    font-size: 0.75rem;
    margin: 0.5rem;
}

.containerBody {
    display: flex;
    flex-direction: column;
    height: 90%;
    overflow-y: auto;
}

.containerBody > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    padding: 0.2rem 0.2rem 0.2rem 0.7rem;
    border-bottom: 1px solid #ccc;
    line-height: 1.5rem;
    display: flex;
    min-height: 2rem;
}

.actions {
    position: absolute;
    right: 0;
    bottom: 0;
}

.actions button {
    margin-left: 1rem;
}

.help {
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
}
.containerBody > span > span {
    margin-right: 5px;
}