.closeBtn {
    padding: 0;
}

.closeBtn:focus {
    outline: 0;
    box-shadow: none;
}

.closeBtn > img {
    vertical-align: auto;
}