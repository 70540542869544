.main {
    width: 75vw;
    height: 80vh;
    position: relative;
}

.modalContainer {
    padding: 0 1rem;
    height: 90%;
    overflow-y: auto;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
}

.instructions {
    width: 20%;
    height: calc(90% - 1rem);
    margin: 0.6rem 0.7rem;
}

.actions {
    position: absolute;
    right: 0;
    bottom: 0;
}

.actions button {
    margin-left: 1rem;
}

.filterNode {
    height: 2rem;
    display: flex;
    align-items: center;
    position: relative;

}

.filterNode span {
    width: 40%;
    white-space: nowrap;
    overflow: hidden;
    margin-left: 0.7rem;
    text-overflow: ellipsis;
   display: block;
}

.editButtons {
    display: flex;
    position: absolute;
    right: 1rem;
    top: 0;
}

.outputChannelHeading {
    display: flex;
    justify-content: space-between;
    width: 100%;
}