.main {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.label {
    width: 22%;
}

.label label {
    margin-bottom: 0;
}

.item {
    width: 36%;
    height: 2rem;
    padding-left: 0.3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 3px;
    position: relative;
}

.item>span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 2rem);
    padding: 0.3rem 0.2rem 0.1rem 0.2rem;
    line-height: 1.5rem;
    display: block;
}

.actions {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
}

.addValue {
    width: 40%;
    text-align: center;
}