.values {
    display: inline-block;
    border: 1px solid #ccc;
    width: 15rem;
    height: 100%;
    overflow-y: auto;
    position: relative;
}

.valueListHeader {
    display: flex;
    position: sticky;
    padding: 0.25rem;
    top: 0;
    background-color: white;
    height: 2.5rem;
    z-index: 1;
    border-bottom: 1px solid #ccc ;
}

.valueListHeader > h3 {
    font-size: 0.75rem;
    margin: 0.5rem;
}

.addValueBtn {
    margin-left: auto;
    margin-right: 0;
}

.valueList {
    overflow-y: auto;
}

.valueList > ol {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.valueList > ol > li {
    height: 2rem;
    padding-left: 1rem;
}

.valueListFooter {
    display: flex;
    position: absolute;
    padding: 0.25rem;
    height: 2.5rem;
    bottom: 0;
    right: 0;
    background-color: white;
    z-index: 1;
}