.main {
    border: 1px solid #ccc;
    min-width: 40rem;
    max-width: 40rem;
    border-radius: 2px;
    height: 97%;
    margin: 0 0.8rem;
}

.containerHeader {
    padding-top: 1rem;
    height: 10%;
    display: flex;
    flex-direction: row;
    padding-left: 1rem;
}

.content {
    width: 100%;
    height: 90%;
}
