.loginPageContainer{
    display: flex;
    /* flex-direction: row; */
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    margin-left: 1rem;
    gap:2rem;
}

.htmlViewerContainer{
    width: 100%;
    height: 100%; 
 }
 
 .htmlViewer{
     width: 60vw;
     height: 35vw;
     background-color: white;
 }
 
 .htmlViewer iframe{
     width: 100%;
     height: 100%;
 }

 .menuContainer{
    margin-top: 1rem;
    margin-right: 1rem;
    display: flex;
    justify-content: end;

}