.text {
    position: relative;
    width: 11.3rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.editText {
    width: 11.3rem;
    height: 2rem;
    position: relative; 
}
