.main {
    width: 35vw;
    height: 65vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.25rem;
    padding-left: 2rem;
    padding-right: 2rem;
    text-align: left;
    position: relative;
}

.main h3 {
    width: 100%;
}

.selectionContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.selectionContainer select {
    margin-right: 0.5rem;
    width: 12.5rem;
    height: 2rem;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.tableContainer {
    margin-top: 1rem;
    width: 100%;
    height: 70%;
    overflow-x: auto;
}

.table {    
    min-width: 26rem;
    width: 100%;
    height: 95%;
    border: 1px solid #ccc;
    position: relative;
}

.tableItem {
    width: 100%;
    overflow-y: auto;
}

.tableItem>table>thead tr th{
    padding-left: 1rem;
    position: sticky;
    top: 0;
    background-color: white;
    border-bottom: 1px solid #ccc;
    z-index: 1;
}

.tableItem>table>tbody tr td {
    padding-left: 1rem;
}

.addValueContainer {
    position: sticky;
    top: 0;
    background-color: white;
    width: 50%;
    padding: 0.25rem;
} 

.tableItem>table {
    border-collapse: separate;
    border-spacing: 0;
} 

.actionBtn {
    position: absolute;
    bottom: 0;
    right: 0;
}

.actionBtn button {
    margin-left: 1rem;
}

.valueListFooter {
    display: flex;
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 0.25rem;
    background-color: white;
    z-index: 1;
}

.addValueBtn {
    margin-left: auto;
    margin-right: 0;
}