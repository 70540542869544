.main {
    width: 40vw;
    height: 40vh;
    padding: 0.25rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.body {
    width: 100%;
    height: calc(100% - 3rem);
}

.messageBox {
    width: calc(100% - 1rem);
    height: calc(100% - 1rem);
    border: 1px solid #ccc;
    padding: 0.5rem;
    border-radius: 3px;
    resize: none;
    font-family: monospace;
    font-size: 0.85rem;
    line-height: normal;
    box-sizing: content-box;
}

.messageBox:focus {
    outline: 0;
}

.actionBtn {
    position: absolute;
    bottom: 0;
    right: 0;
}

.actionBtn button {
    margin-left: 1rem;
}