.main {
    width: 75vw;
    height: 70vh;
    font-size: 0.8rem;
    position: relative;
    padding: 0.25rem;
    padding-left: 2rem;
    padding-right: 2rem;
}

.action {
    position: absolute;
    right: 0;
    bottom: 0;
}

.action button {
    margin-left: 1rem;
}