:global(.headerMain) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 3rem;
    overflow: revert;
    padding-left: 1rem;
    padding-right: 1rem;
    color: white;
    position: relative;
}

:global(.headerLogo) {
    background-size: contain;
    width: 16rem;
    height: 0.8rem;
}

.tabs {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.logoutContainer {
    height: 100%;
    display: flex;
    width: 6rem;
    justify-content: right;
}