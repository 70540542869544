.main {
    padding-top: 0.8rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    width: 100vw;
    height: calc(100vh - 3rem);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.leftContainer {
    width: calc((100% - 8rem) / 2);
    height: 100%;
}

.middleContainer {
    width: 7.5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.middleContainer button {
    margin-bottom: 1rem;
    width: 7.5rem;
}

.middleContainer button:last-child {
    margin-top: 1rem;
    width: 7.5rem;
}

.rightContainer {
    width: calc((100% - 8rem) / 2);
    height: 100%;
}

.leftContainerAdmin {
    width: 70%;
    height: 100%;
}

.rightContainerAdmin {
    width: 30%;
    height: 100%;
}

.container {
    position: relative;
    text-align: left;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 2rem;
}

.headerSelect {
    position: absolute;
    right: 0;
}

.headerSelect select {
    height: 2rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-left: 1rem;
}

.headerSelect button {
    margin-left: 1rem;
}

.gridTable {
    margin-top: 1rem;
    width: 100%;
    height: calc(100% - 14rem);
}

.orderViewer {
    display: flex;
    justify-content: center;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: 100%;
    height: 10rem;
}

.activeBtn {
    display: flex;
    justify-content: right;
    height: 2rem;
}

.previewContainer {
    margin-top: 1rem;
    width: 100%;
    height: calc(100% - 3rem);
    background-color: white;
    position: relative;
}

.previewContainer h3 {
    padding-left: 0.5rem;
    font-weight: 500;
}

.outputPreset {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 0.5rem;
    height: 7.3rem;
    overflow-y: auto;
}

.selectTable {
    padding-left: 1.5rem;
    padding-bottom: 0.5rem;
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
}

.selectTable select,
.selectTable input {
    height: 2rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
}

.selectTable>div>select:focus {
    outline: none;
}

.preview {
    width: 100%;
    height: calc(100% - 18rem);
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.printBtn {
    position: absolute;
    bottom: 0.5rem;
    right: 6rem;
}

.printBtn > button {
    margin-left: 1rem;
}

.updateBtn {
    height: inherit;
    display: flex;
    align-items: flex-end;
    white-space: nowrap;
}

.box {
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
    width: 32%;
}