.main {
    width: 100%;
    height: 45%;
    border: 1px solid #ccc;
    border-radius: 2px;
    position: relative;
}

.heading {
    display: flex;
    padding: 0.25rem;
    border-bottom: 1px solid #ccc;
    width: 100%;
    height: 2.5rem;
}

.heading>h3 {
    font-size: 0.75rem;
    margin: 0.5rem;
}

.body {
    height: calc(100% - 2.5rem);
    overflow-y: auto;
}

.body>span,
.body>div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    border-bottom: 1px solid #ccc;
    line-height: 1.5rem;
    display: block;
    height: 2rem;
}

.body>span{
    padding: 0.2rem 0.2rem 0.2rem 0.7rem;
}

.body select {
    width: 100%;
    height: 1.7rem;
    border: none;
}

.btn {
    position: absolute;
    height: 2.5rem;
    right: 0;
    bottom: 0;
}