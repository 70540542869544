.main {
    height: 2rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.2rem 0.2rem 0.2rem 0.7rem;
}

.main>span {
    margin-left: 0.7rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}