.align-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.align-vertical-center {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.align-horizontal-center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

h2 {
    font-size: 1rem;
}

h3 {
    font-size: 0.9rem;
}

p, span, label, li {
    font-size: 0.75rem;
}

input, select {
    font-size: 0.75rem;
    padding-left: 0.5rem;
}

input:focus, select:focus, button:focus {
    outline: none;
}

tr {
    font-size: 0.75rem;
}

th {
    font-size: 0.75rem;
    font-weight: 500;
}

.table>thead>tr>th {
    border-top: none;
}

.btn {
    font-size: 0.75rem;
}
.table>tbody>tr>td,
.table>thead>tr>th {
    padding: 0.2rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    margin: 0;
    text-align: center;
    vertical-align: middle;
}

.row {
    margin: 0;
}

.App {
    height: 100vh;
    background-color: rgb(240, 236, 236);
}

.order-grid .rgt-footer {
    display: none;
}

.rgt-cell-header {
    font-weight: 600;
}

.rgt-search-label {
    line-height: 1rem;
    font-weight: 500;
    font-size: 0.75rem !important;
}

.rgt-search-input {
    font-size: 0.75rem !important;
    height: 2rem !important;
    width: 10rem !important;
    flex: none !important;
    margin-left: 0.5rem !important;
    margin-right: 0 !important;
    position: absolute;
    top: 0.75rem !important;
    left: 7rem !important;
    border: 1px solid #ccc !important;
    padding: 0.3rem !important;
}

.rgt-search-container {
    display: inline-block !important;
    width: 19rem !important;
    height: 3rem !important;
    flex: none !important;
    padding: 10px 0 10px 10px !important;
    position: relative !important;
}

.rgt-search-container input {
    padding-bottom: 10px !important;
}

.rgt-columns-manager-popover-title {
    padding: 0 1rem !important;
    margin-bottom: 0.5rem !important;
    font-size: 0.75rem !important;
}

.rgt-cell {
    min-height: 2.75rem !important;
    max-height: 2.75rem !important;
}

.rgt-cell-header {
    min-height: 2rem !important;
    max-height: 2rem !important;
}

.rgt-cell-header-inner {
    padding: 0 !important;
    padding-left: 1rem !important;
}

.rgt-text-truncate {
    font-size: 0.75rem !important;
}

.rgt-columns-manager-popover-row > label {
    padding: 0.3rem 2rem 0.3rem 1rem;
    width: 100%;
    font-size: 0.75rem;
}

.rgt-cell-orderId {
    padding-left: 1.4rem;
    position: relative;
    vertical-align: middle;
    overflow: hidden;
}

.rgt-cell-dealerOrderId, .rgt-cell-jobStatus {
    padding-left: 1rem;
    vertical-align: middle;
    overflow: hidden;
}

.rgt-cell-orderId p, .rgt-cell-dealerOrderId p, .rgt-cell-jobStatus p {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
}

.rgt-cell-header-edit .rgt-cell-header-inner, .rgt-cell-header-copies .rgt-cell-header-inner, .rgt-cell-header-amountPrinted .rgt-cell-header-inner {
    padding: 0 !important;
    display: flex !important;
    justify-content: center !important;
}

.rgt-cell-copies, .rgt-cell-amountPrinted {
    text-align: center;
}

.rgt-cell-edit {
    display: flex;
    justify-content: center;
}

.rgt-cell-header-virtual-col {
    width:0 !important;
}

.rgt-cell-virtual {
    width: 0 !important;
}

.rgt-container-overlay {
    visibility: hidden !important;
}

.order-grid .rgt-row-selected {
    background: #EAEAEA;
}

.react-switch-handle {
    width: 1.25rem !important;
    height: 1.25rem !important;
    box-shadow: none !important;
    top:0;
}

.react-switch-bg {
    width: 2.75rem !important;
    height: 1.25rem !important;
    border: 1px solid #ccc;
}

/* Hide the SVG icons */
.react-switch-bg > div {
    display: none;
}

label, span, li, tr, h1, h2, h3, p {
    user-select: none;
}

button:disabled{
    cursor: default;
}
