.main {
    width: 20vw;
    height: 15vh;
    display: flex;
    flex-direction: column;
    padding: 10px;
    position: relative;
}

.main input {
    width: 100%;
    border: 0;
    height: 2rem;
    margin: 0;
    display: block;
    padding: 0 0 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    outline: none;
}

.main p {
    color: red;
    padding-top: 0.5rem;
}

.main label {
    font-size: 0.9rem;
}

.actions {
    position: absolute;
    right: 0;
    bottom: 0;
}
