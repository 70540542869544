.main {
    width: 100%;
    height: 2.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mainWithAddButtonAtBottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.label {
    width: 22%;
}

.label label {
    margin-bottom: 0;
}

.select {
    width: 35%;
}

.select select {
    width: 100%;
    height: 1.5rem;
}

.clearButton {
    width: 20%;
    text-align: center;
}

.addValueButton {
    width: 22%;
    text-align: center;
}

.addValueButtonAtBottom {
    text-align: right;
}