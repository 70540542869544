.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.icon img {
    width: 40%;
}
.title {
    font-size: 1rem;
    margin-top: 1rem;
    color: black;
}

a:hover {
    text-decoration: none;
}