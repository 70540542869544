.main {
    width: 80vw;
    height: 80vh;
    display: flex;
    justify-content: space-between;
}

.leftContainer {
    width: 35%;
    height: 100%;
}

.rulesTable {
    width: 100%;
    height: 90%;
    overflow: auto;
}

.leftHeader {
    margin-bottom: 0.5rem;
    width: 100%;
    display: flex;
    justify-content: left;
}

.leftHeader p {
    width: 80%;
}

.leftHeader button {
    float: right;
    height: 2rem;
}

.rightContainer {
    width: 60%;
    height: 100%;
    position: relative;
}

.ruleItems {
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.actions {
    position: absolute;
    right: 0;
    bottom: 0;
}

.actions button {
    margin-left: 1rem;
}
