.main {
    width: 55vw;
    height: 55vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    padding: 15px;
}

.leftContainer {
    width: 38%;
    height: 95%;
    border-right: 1px solid #CCC;
    overflow-y: auto;
}

.rightContainer {
    width: 56%;
    height: 95%;
    overflow-y: auto;
}

.filterMatchRules select {
    height: 1.5rem;
    width: 10rem;
}

.filterValues {
    margin-top: 20px;
    height: 68%;
}

.actions {
    position: absolute;
    display: flex;
    right: 0;
    bottom: 0;
    justify-content: right;
}

.actions button {
    margin-left: 1rem;
}