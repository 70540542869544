.main {
    height: 7rem;
    min-width: 15rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 0.1rem solid gainsboro;
}

.leftContainer {
    height: 100%;
    width: 70%;
    display: flex;
    flex-direction: row;
}

.actions {
    width: 4rem;
    display: flex;
    align-items: center;
    padding-left: 0.4rem;
}

.text {
    padding-top: 3rem;
    width: calc(100% - 4rem);
}

.rightContainer {
    height: 100%;
    width: 30%;
    display: flex;
    flex-direction: row;
    justify-content: right;
}