.main {
    padding-top: 0.8rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    width: 100vw;
    height: calc(100vh - 3rem);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.leftContainer {
    width: 50%;
    height: 100%;
}

.backButton {
    width: 100%;
    height: 1rem;
}

.container {
    margin-top: 1rem;
    width: 100%;
    height: 1rem;
    display: flex;
    flex-direction: row;
}

.usersTable {
    margin-top: 1rem;
    width: 100%;
    height: calc(100% - 4rem);
    overflow-y: auto;
    background-color: white;
}

.usersTable>table>thead tr th {
    position: sticky;
    top: 0;
    background-color: white;
    border-bottom: 1px solid #ccc;
    z-index: 1;
}

.usersTable>table {
    border-collapse: separate;
    border-spacing: 0;
}