.listContainer {
    height: 18rem;
    margin-bottom: 1rem;
}

.action {
    text-align: right;
}

.action button {
    margin-left: 1rem;
}