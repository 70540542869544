.main {
    width: 55vw;
    height: 48vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
}

.leftContainer {
    width: 22%;
    height: 100%;
    overflow-y: auto;
}

.middleContainer {
    width: 22%;
    height: 100%;
    overflow-y: auto;
}

.rightContainer {
    width: 22%;
    height: 100%;
    overflow-y: auto;
}

.previewContainer {
    width: 26%;
    height: 100%;
    overflow-y: auto;
}

.previewContents {
    width: 90%;
    height: 90%;
}

.previewHeading {
    display: flex;
    justify-content: left;
}

.slotField {
    display: flex;
    justify-content: left;
    width: 100%;
    margin-bottom: 0.7rem;
}

.slotField input, .slotField select {
    width: 100%;
    height: 1.5rem;
    padding-left: 0.1rem;
}

.actions {
    position: absolute;
    display: flex;
    right: 0;
    bottom: 0;
    justify-content: right;
}

.actions button {
    margin-left: 1rem;
}