.main {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.menu {
    position: absolute;
    top: 0.35rem;
    right: 0.35rem;
}

:global(.loginFormLogo) {
    background-size: contain;
    width: 70%;
    height: 0.8rem;
}

.environment {
    margin-top: 2rem;
    font-weight: 600;
    font-size: 1.1rem;
}

.loginForm {
    margin: 1.25rem auto;
    text-align: left;
}

.loginForm input {
    margin-bottom: 0.75rem;
}

.loginForm h2 {
    font-size: 1.25rem;
    margin: 1.25rem 0 1rem;
}

.loginForm label {
    font-size: 0.75rem;
}

.form-field {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 85%;
    height: 2rem;
}

.post-submit-button {
    width: 100%;
    margin-top: 1rem;
}

.loginForm label {
    color: #747474;
    opacity: 1;
}

:global(.rdgConnectLogo) {
    margin-top: 1rem;
    width: 50%;
    background-size: contain;
    height: 4.5rem;
}
.rdgConnectLogoContainer {
    margin-top: 1rem;
    width: 50%;
}

.rdgConnectLogoContainer>img {
    width: 100%;
}

.environmentDiv {
    /* padding-top: 1rem; */
    padding-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    position: relative;
}

.environmentDiv input {
    /* margin-left: 1rem; */
    /* border: 1px solid #ccc; */
    /* border-radius: 2px; */
    height: 100%;
    border: none;
    border-left: 1px solid #ccc ;
    width: 100%;
    font-size: 1rem;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    margin-bottom: 0;
}

.inputBox{
    display: flex;
    gap: 0.25rem;
    width: max-content;
    border: 1px solid #ccc;
    height: 2rem;
    /* justify-content: center; */
    align-items: center;
    border-radius: 4px;
    width: 85%;
    /* padding-left: 0.25rem; */

}

.emailContainer{
    display: flex;
    gap: 1rem;
}

.hint{
    background-color: white;
    color: black;
    height: 2rem;
    display: flex;
    justify-content: center;
    width: 2rem;
    font-size: 36rem;
    border-radius: 50%;

}

.hint p{
    font-size: 1.25rem;
}
