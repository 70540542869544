.filterLevel {
    display: inline-block;
    border: 1px solid #ccc;
    min-width: 15rem;
    max-width: 15rem;
    height: calc(100% - 3rem);
    overflow-y: auto;
    margin-top: 2rem;
    position: relative;
}

.filterLevelHeader {
    display: flex;
    position: sticky;
    padding: 0.25rem;
    top: 0;
    background-color: white;
    z-index: 1;
    border-bottom: 1px solid #ccc;
    height: 2.5rem;
}

.filterLevelHeader > h3 {
    font-size: 0.75rem;    
    margin: 0.5rem;
}

.filterLevelActionBtns {
    position: absolute;
    right: 0.25rem;
    top: 0.25rem;
}

.filterLevelList {
    height: calc(100% - 5rem);
    overflow-y: auto;
}

.filterLevelList > ol {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.filterLevelList > ol > li {
    height: 2rem;
    padding-left: 0.7rem;
}

.filterLevelFooter {
    position: absolute;
    padding: 0.25rem;
    bottom: 0;
    right: 0;
    background-color: white;
    z-index: 1;
    height: 2.5rem;
}

.addBtnWrapper {
    margin-left: auto;
    margin-right: 0;
}
