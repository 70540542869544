.main {
    width: 75vw;
    height: 88vh;
    position: relative;
}

.container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: calc(100% - 3rem);
   justify-content: space-around;

}

.actions {
    position: absolute;
    right: 0;
    bottom: 0;
}

.actions button {
    margin-left: 1rem;
}

.box{
    display: flex;
    flex-direction: column;
    align-content: space-around;
    width: 18rem;
    padding-left: 1rem;
}



.channelSelection{
    display: flex;
}

.rightBorder{
    border-right: 2px solid var(--rgt-shadow-color) ;
}

.nameSelectionLayoutMain{
    row-gap: 3rem;
    margin-top: 11.5rem;
    padding-right: 1rem; 
    padding-left: 1rem;
}

.jobContainer{
    padding-right:1rem;
     height:100%;
     margin-top: 8rem;
}

.layoutPreviewContainer{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 1rem;
    border-bottom: 2px solid var(--rgt-shadow-color);
}

.layoutPreview{
    height: 16rem;
    margin: auto;
    width: 16rem;
    border:1px solid var(--rgt-shadow-color);
   
}

.layoutPreview div{
    justify-content: center;
}

.outputPresetContainer{
    height:45%;
    padding-top:0.5rem; 
    padding-right:1rem;
}


.outputPresetName{
    display: flex;
    flex-direction: column;
}
.operationCommand{
    display: flex; 
    justify-content: space-between;
    padding: 3px 10px;
}

.queuePresetContainer{
    padding-right: 1rem; 
    padding-top: 4.8rem;
    height:100%;
}

.printSettingContainer{
    padding-right: 1rem; 
    margin-top:4.8rem;
}

.selectMenu {
    height: 2rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 16rem;
}

:root {
    --trPadding: 0.7rem;
}

table.filterParms {
    width: 100%;
}

table.filterParms td {
    padding-top: var(--trPadding);
    padding-bottom: var(--trPadding);
    padding-right: var(--trPadding);   
}
  
table.filterParms td:first-child {
    padding-left: var(--trPadding);
    padding-right: 0;
}

.settingBoxHeading {
    flex: 1 1 0%;
    justify-content: space-between;
    display: flex;
}