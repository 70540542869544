.main {
    width: 100%;
    height: 100%;
    min-height: 4rem;
    display: flex;
    flex-direction: row;
    position: relative;
}

.content {
    width: 100%;
    height: 100%;
}

.title {
    margin-left: 0.5rem;
    width: 100%;
    height: 1.2rem;
    text-align: left;
    box-sizing: border-box;
    color: #1E90FF;
    font-weight: bold;
    font-size: 100%;
}

.body {
    width: 100%;
    height: calc(100% - 1.2rem);
    text-align: left;
}

.bodyContent {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    margin-left: 4rem;
    width: calc(100% - 4rem);
    max-height: 8rem;
    overflow-y: auto;
    color: #1E90FF;
    white-space: pre-wrap;
    font-size: 0.75rem;
}

.actions button {
    position: absolute;
    top: 0;
    right: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}